<template>
  <requester 
    :uuid="uuid"
    :key="uuid"
    i18nKey="requester"
    :middlewareID="middlewareID"
    :middlewareType="middlewareType"
    v-model="agents"
    type="default"

    @saved="$emit('saved', $event)"
    @close="$emit('close', $event)"
  />
</template>


<script>
import Requester from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/Requester.vue";
import Middlewares from "@/custom/class/Enum/Middlewares.js"

export default {
  components: {
    Requester
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    middlewareID: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      middlewareType: new Middlewares().items.find(el=> el.id == 10),
    }
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
</style>